import React, { useMemo, useRef, useState } from "react";
import {
  ClientFilters,
  usePaginatedTrends,
} from "@/hooks/usePaginatedTrends.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card.tsx";

import { Button } from "@/components/ui/button.tsx";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area.tsx";
import { ChartExpander } from "@/components/ChartExpander/ChartExpander.tsx";
import { formatNumber } from "@/lib/utils.ts";
import { makeLinkFromAsin } from "@/helpers/helpers.ts";
import { FilterContainer } from "@/components/filters/FilterContainer/FilterContainer.tsx";
import {
  ColumnSettingsByColumnNameInOrder,
  POPUP_COLUMNS,
} from "@/hooks/ColumnSettingsByColumnName/ColumnSettingsByColumnNameInOrder.tsx";
import { ColumnsSettings } from "@/components/ColumnsSettings/ColumnsSettings.tsx";
import { TableFilterDialog } from "@/components/dialogs/TableFiltersDialog/TableFiltersDialog.tsx";
import { TopProductPopover } from "@/components/TopProductPopover/TopProductPopover.tsx";
import { SortDropdown } from "@/components/sort/SortDropdown.tsx";

import { TablePagination } from "@/components/table/TablePagination.tsx";
import {
  CircleBackslashIcon,
  Cross1Icon,
  HamburgerMenuIcon,
  HeartFilledIcon,
  HeartIcon,
  ResetIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import useAuthStore from "@/store/useAuthStore.tsx";
import { useNavigate } from "react-router-dom";
import { useMe } from "@/hooks/useMe.tsx";
import { useUserSettings } from "@/hooks/useUserSettings.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";

export type OptionFilterReturn = {
  queryKey: ClientFilters;
  filterValue: string | number | boolean | null | object;
};

export type SortColumnOption = {
  columnKey: ClientFilters;
  sortValue: 1 | -1 | 0;
};

export const TrendsPage: React.FC = () => {
  const { me, refetchMe } = useMe({});
  const {
    paginatedData = [],
    tableHeadRow,
    addFilterHandler,
    addSortHandler,
    hideColumn,
    hiddenRowsIds,
    fetchTable,
    // moveColumn,

    totalPagesCount,
    totalRows,
    currentPaginationPage,
    moveToPaginationPage,
    isPlaceholderData,
    changeListType,
    listType,
    isLoading,
    isFetching,
  } = usePaginatedTrends();

  const { deleteSavedSearchById } = useUserSettings();

  const def = {
    id: "default",
    name: "Default search",
    applied_filters: [],
  };
  const savedSearches = useMemo(
    () => (me?.saved_searches ? [def, ...me?.saved_searches] : [def]),
    [me?.saved_searches],
  );

  const moveToPaginationPageHandler = (pageNum) => {
    moveToPaginationPage(pageNum);
    setTimeout(() => scrollToTop(), 100);
  };
  const filteredData = paginatedData || [];

  const addSort = ({ sort }: { sort: SortColumnOption[] }) => {
    addSortHandler({ newSort: sort });
  };

  const {
    addTrendToBlockList,
    addTrendToWatchList,
    removeTrendsByListName,
    addFiltersList,
  } = useUserSettings();

  const addFilters = async ({ filters }: { filters: OptionFilterReturn[] }) => {
    addFilterHandler({ newFilters: filters });
    await addFiltersList({ filterList: filters });
  };

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const addSelectedRowId = (id: number) => {
    if (selectedRows.some((r) => r === id)) {
      return;
    }

    setSelectedRows((prevRows) => [...prevRows, id]);
  };

  const [savedSearchFilters, setSavedSearchFilters] = useState([]);

  const removeSelectedRowId = (id: number) => {
    setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
  };

  const { clearToken } = useAuthStore();

  const navigation = useNavigate();

  const isNegative = (value: string) => {
    return value.startsWith("-");
  };

  const ValueDisplay = ({ value }) => {
    const negative = isNegative(value);
    const hasPercentage = value.includes("%");
    const numericValue = hasPercentage
      ? parseFloat(value.slice(0, -1))
      : parseFloat(value);
    const finalValue = formatNumber({
      number: numericValue,
      isPercentage: hasPercentage,
    });

    return (
      <p className={`${negative ? "text-red-800" : "text-green-800"}`}>
        {hasPercentage ? finalValue : value}
      </p>
    );
  };

  const signOut = () => {
    clearToken();
    navigation("/");
  };

  const scrollAreaRef = useRef<any>(null);

  const scrollToTop = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="flex flex-col max-h-screen">
      <div className="flex w-full flex-wrap items-center my-[12px] gap-4 px-[12px]">
        <FilterContainer>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size={"sm"} variant="outline">
                <HamburgerMenuIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuGroup>
                <DropdownMenuLabel>Pages</DropdownMenuLabel>

                <DropdownMenuItem>
                  Trends
                  {/*<DropdownMenuShortcut>Page</DropdownMenuShortcut>*/}
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem>Profile</DropdownMenuItem>
                <DropdownMenuItem>Billing</DropdownMenuItem>
                <DropdownMenuItem>Settings</DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />

              <DropdownMenuItem>
                <button onClick={signOut}>Sign out</button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </FilterContainer>
        <TableFilterDialog
          disabled={isFetching}
          savedSearchFilters={savedSearchFilters}
          addFilters={addFilters}
          onSearch={async () => {
            await fetchTable();
            moveToPaginationPageHandler(1);
          }}
        />
        {savedSearches.length !== 0 && (
          <FilterContainer>
            <Select
              onValueChange={(f) => {
                const newFilters = [...f];
                addFilters({ filters: newFilters });
                setSavedSearchFilters(newFilters);
                setTimeout(() => setSavedSearchFilters([]), 500);
              }}
            >
              <SelectTrigger className="w-fit">
                <SelectValue
                  className={"bg-purple-300 text-sm "}
                  placeholder={"Saved searches"}
                />
              </SelectTrigger>
              <SelectContent>
                {savedSearches.map((option) => (
                  <div className={"flex items-center"}>
                    <SelectItem
                      className={"cursor-pointer hover:bg-pallete-orange/10"}
                      key={option.id}
                      value={option.applied_filters}
                    >
                      {option.name}
                    </SelectItem>
                    {option.id !== "default" && (
                      <div className={"p-[5px]"}>
                        <TrashIcon
                          onClick={() =>
                            deleteSavedSearchById({ id: option.id })
                          }
                          className={"cursor-pointer hover:text-pallete-orange"}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </SelectContent>
            </Select>
          </FilterContainer>
        )}
        <FilterContainer>
          <ColumnsSettings
            hiddenRowsIds={hiddenRowsIds}
            hideColumn={hideColumn}
          />
        </FilterContainer>
        <FilterContainer>
          <Select onValueChange={changeListType}>
            <SelectTrigger className="w-[180px] h-8">
              <SelectValue defaultValue="trends" placeholder={"Trends"} />
            </SelectTrigger>
            <SelectContent size={"sm"}>
              <SelectItem value="trends">Trends</SelectItem>
              <SelectItem value="watch">Watch list</SelectItem>
              <SelectItem value="black">Black list</SelectItem>
            </SelectContent>
          </Select>
        </FilterContainer>
        <div className={" flex w-fit  gap-[16px]"}>
          {/*<FilterContainer>*/}
          {/*  <Button*/}
          {/*    size={"sm"}*/}
          {/*    variant="outline"*/}
          {/*    onClick={() => {*/}
          {/*      setSelectedRows([]);*/}
          {/*      setShowSelect(false);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Cross1Icon className={"mr-[6px]"} />*/}
          {/*    Hide*/}
          {/*  </Button>*/}
          {/*</FilterContainer>*/}

          {listType === "trends" && (
            <div className={"flex w-fit gap-[16px]"}>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await addTrendToWatchList({ trends_ids: selectedRows });
                    setSelectedRows([]);
                    void fetchTable();
                    await refetchMe();
                  }}
                >
                  <HeartIcon className={"mr-[6px]"} />
                  Add to watchlist
                </Button>
              </FilterContainer>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await addTrendToBlockList({ trends_ids: selectedRows });
                    setSelectedRows([]);
                    void fetchTable();
                    await refetchMe();
                  }}
                >
                  <TrashIcon className={"mr-[6px]"} />
                  Add to blacklist
                </Button>
              </FilterContainer>
              {selectedRows.length !== 0 && (
                <FilterContainer>
                  <Button
                    disabled={selectedRows.length === 0}
                    size={"sm"}
                    variant="outline"
                    onClick={() => {
                      setSelectedRows([]);
                    }}
                  >
                    <Cross1Icon className={"mr-[6px]"} />
                    Cancel
                  </Button>
                </FilterContainer>
              )}
            </div>
          )}

          {listType === "watch" && (
            <div className={"flex w-fit gap-[16px]"}>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await removeTrendsByListName({
                      trends_ids: selectedRows,
                      listType: "watch",
                    });
                    setSelectedRows([]);
                    void fetchTable();
                    await refetchMe();
                  }}
                >
                  <ResetIcon className={"mr-[6px]"} />
                  Remove from watchlist
                </Button>
              </FilterContainer>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await addTrendToBlockList({ trends_ids: selectedRows });
                    setSelectedRows([]);
                    void fetchTable();
                    await refetchMe();
                  }}
                >
                  <TrashIcon className={"mr-[6px]"} />
                  Add to blacklist
                </Button>
              </FilterContainer>
              {selectedRows.length !== 0 && (
                <FilterContainer>
                  <Button
                    disabled={selectedRows.length === 0}
                    size={"sm"}
                    variant="outline"
                    onClick={() => {
                      setSelectedRows([]);
                    }}
                  >
                    <Cross1Icon className={"mr-[6px]"} />
                    Cancel
                  </Button>
                </FilterContainer>
              )}
            </div>
          )}

          {listType === "black" && (
            <div className={"flex w-fit gap-[16px]"}>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await removeTrendsByListName({
                      trends_ids: selectedRows,
                      listType: "black",
                    });
                    setSelectedRows([]);
                    void fetchTable();
                    await refetchMe();
                  }}
                >
                  <ResetIcon className={"mr-[6px]"} />
                  Remove from blacklist
                </Button>
              </FilterContainer>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await addTrendToBlockList({ trends_ids: selectedRows });
                    setSelectedRows([]);
                    void fetchTable();
                    await refetchMe();
                  }}
                >
                  <HeartIcon className={"mr-[6px]"} />
                  Add to watch list
                </Button>
              </FilterContainer>
              {selectedRows.length !== 0 && (
                <FilterContainer>
                  <Button
                    disabled={selectedRows.length === 0}
                    size={"sm"}
                    variant="outline"
                    onClick={() => {
                      setSelectedRows([]);
                    }}
                  >
                    <Cross1Icon className={"mr-[6px]"} />
                    Cancel
                  </Button>
                </FilterContainer>
              )}
            </div>
          )}
        </div>
      </div>
      <ScrollArea
        ref={scrollAreaRef}
        className="max-w-[100vw] min-w-[100vw] h-screen max-h-full"
        type={"always"}
      >
        <div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead
                  className={` sticky left-0 z-[29] top-0 text-black text-center font-xs font-normal border-r-gray-300 bg-gray-100 border-r  py-[6px] min-w-[50px]`}
                >
                  Add
                </TableHead>
                {tableHeadRow.map((column, i) => {
                  const hiddenRow =
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ColumnSettingsByColumnNameInOrder?.[column]?.hidden;
                  const isHidden = hiddenRow || hiddenRowsIds.includes(column);
                  const shownColumnName =
                    ColumnSettingsByColumnNameInOrder?.[column]?.shownName;

                  const tooltipText =
                    ColumnSettingsByColumnNameInOrder?.[column]?.tootipText;

                  const isNewKey = column === "new_key";
                  const isSvChange = column === "sv_change";
                  const isSearchTerm = column === "search_term";
                  return (
                    <TableHead
                      key={`${column}-${i}`}
                      className={`${isHidden || Object.keys(POPUP_COLUMNS).includes(column) ? "hidden" : ""}
                   text-black text-center font-xs font-normal border-r-gray-300 bg-gray-100 border-r  sticky top-[0px] !z-[1] py-[6px] min-w-[50px]`}
                    >
                      {isSvChange ? (
                        <>
                          <span className={"text-xs font-medium"}>
                            {shownColumnName || column}
                          </span>
                          <div className="flex flex-row justify-around mt-2">
                            <div className="flex flex-col gap-0.5">
                              <HoverCard openDelay={500}>
                                <HoverCardTrigger className="text-xs font-medium text-text-2 cursor-help">
                                  12 mo
                                </HoverCardTrigger>
                                <HoverCardContent
                                  side="left"
                                  className={"w-max-96"}
                                >
                                  Change in search volume in the past 12 months
                                  (0-12 months), compared to the previous 12
                                  months (12-24 months ago).
                                </HoverCardContent>
                              </HoverCard>
                              <div className="flex flex-row gap-2">
                                <SortDropdown
                                  valueLabel={"%"}
                                  queryKey={"sv_increase_12_mnth_ratio"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                                <p>/</p>
                                <SortDropdown
                                  valueLabel={"#"}
                                  queryKey={"sv_increase_12_mnth_abs"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                              </div>
                            </div>

                            <div className="flex flex-col gap-0.5">
                              <HoverCard openDelay={130}>
                                <HoverCardTrigger className="text-xs font-medium text-text-2 cursor-help">
                                  6 mo
                                </HoverCardTrigger>
                                <HoverCardContent
                                  side="left"
                                  className={"w-max-96"}
                                >
                                  Change in search volume in the past 6 months
                                  (0-6 months), compared to the previous 6
                                  months (6-12 months ago).
                                </HoverCardContent>
                              </HoverCard>
                              <div className="flex flex-row gap-2">
                                <SortDropdown
                                  valueLabel={"%"}
                                  queryKey={"sv_increase_6_mnth_ratio"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                                <p>/</p>
                                <SortDropdown
                                  valueLabel={"#"}
                                  queryKey={"sv_increase_6_mnth_abs"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                              </div>
                            </div>

                            <div className="flex flex-col gap-0.5">
                              <HoverCard openDelay={130}>
                                <HoverCardTrigger className="text-xs font-medium text-text-2 cursor-help">
                                  3 mo
                                </HoverCardTrigger>
                                <HoverCardContent
                                  side="left"
                                  className={"w-max-96"}
                                >
                                  Change in search volume in the past 3 months
                                  (0-3 months), compared to the previous 3
                                  months (3-6 months ago).
                                </HoverCardContent>
                              </HoverCard>
                              <div className="flex flex-row gap-2">
                                <SortDropdown
                                  valueLabel={"%"}
                                  queryKey={"sv_increase_3_mnth_ratio"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                                <p>/</p>
                                <SortDropdown
                                  valueLabel={"#"}
                                  queryKey={"sv_increase_3_mnth_abs"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className={"flex w-full items-center justify-center"}
                        >
                          <SortDropdown
                            queryKey={column}
                            onSelect={addSort}
                            options={
                              isSearchTerm
                                ? "AZ"
                                : isNewKey && !isSearchTerm
                                  ? "bool"
                                  : "arrow"
                            }
                          >
                            {tooltipText ? (
                              <HoverCard openDelay={100}>
                                <HoverCardTrigger>
                                  <span className={"text-xs"}>
                                    {shownColumnName || column}
                                  </span>
                                </HoverCardTrigger>
                                {/*//TODO: sticky table issue*/}
                                <HoverCardContent
                                  side="left"
                                  className={" relative top-[2px] "}
                                >
                                  {tooltipText}
                                </HoverCardContent>
                              </HoverCard>
                            ) : (
                              <span className={"text-xs"}>
                                {shownColumnName || column}
                              </span>
                            )}
                          </SortDropdown>
                        </div>
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            </TableHeader>
            <TableBody className={"min-h-full"}>
              {filteredData?.map((row, i) => {
                const entries: Record<string, any>[] = Object.entries(row);
                const asin = `${row["asin_1"] || row["asin_2"] || row["asin_3"]}`;
                const productLink = makeLinkFromAsin(asin);

                const id = row["search_term_id"];
                const inWatchList = me?.watch_list?.includes(id);

                const inBlackList = me?.black_list?.includes(id);
                return (
                  <TableRow
                    key={row["search_term_id"]}
                    className={`transition-opacity ${isPlaceholderData && "animate-in opacity-50"}  ${inWatchList && "bg-green-600/20"}  ${inBlackList && "bg-red-600/20"}`}
                  >
                    {
                      <TableCell
                        className={
                          "border-r-gray-300 border-r !font-xs !font-normal border-separate  sticky left-[0px] !z-[1]  bg-white "
                        }
                      >
                        <div
                          className={
                            " flex justify-center items-center w-full h-full  "
                          }
                        >
                          <Checkbox
                            checked={selectedRows.includes(
                              row["search_term_id"],
                            )}
                            className={"mr-2"}
                            onCheckedChange={(checked) => {
                              const id = row["search_term_id"];
                              if (checked) addSelectedRowId(id);
                              else removeSelectedRowId(id);
                            }}
                          />

                          {/*{listType === "trends" && (*/}
                          {/*  <div*/}
                          {/*    className={*/}
                          {/*      "flex gap-[4px] items-center justify-center"*/}
                          {/*    }*/}
                          {/*  >*/}
                          {/*    {inWatchList ? (*/}
                          {/*      <HeartFilledIcon*/}
                          {/*        onClick={async () => {*/}
                          {/*          await removeTrendsByListName({*/}
                          {/*            trends_ids: [id],*/}
                          {/*            listType: "watch",*/}
                          {/*          });*/}
                          {/*          void fetchTable();*/}
                          {/*          await refetchMe();*/}
                          {/*        }}*/}
                          {/*        className={*/}
                          {/*          "text-pink-400  hover:text-black cursor-pointer"*/}
                          {/*        }*/}
                          {/*      />*/}
                          {/*    ) : (*/}
                          {/*      <HeartIcon*/}
                          {/*        onClick={async () => {*/}
                          {/*          await addTrendToWatchList({*/}
                          {/*            trends_ids: [id],*/}
                          {/*          });*/}
                          {/*          void fetchTable();*/}
                          {/*          await refetchMe();*/}
                          {/*        }}*/}
                          {/*        className={*/}
                          {/*          "hover:text-pink-400/50 cursor-pointer "*/}
                          {/*        }*/}
                          {/*      />*/}
                          {/*    )}*/}

                          {/*    <TrashIcon*/}
                          {/*      onClick={async () => {*/}
                          {/*        await addTrendToBlockList({*/}
                          {/*          trends_ids: [id],*/}
                          {/*        });*/}
                          {/*        void fetchTable();*/}
                          {/*      }}*/}
                          {/*      className={"hover:text-red-600 cursor-pointer"}*/}
                          {/*    />*/}
                          {/*  </div>*/}
                          {/*)}*/}

                          {/*{listType === "watch" && (*/}
                          {/*  <div*/}
                          {/*    className={*/}
                          {/*      "flex gap-[4px] items-center justify-center"*/}
                          {/*    }*/}
                          {/*  >*/}
                          {/*    <ResetIcon*/}
                          {/*      onClick={async () => {*/}
                          {/*        await removeTrendsByListName({*/}
                          {/*          trends_ids: [id],*/}
                          {/*          listType: "watch",*/}
                          {/*        });*/}
                          {/*        void fetchTable();*/}
                          {/*        await refetchMe();*/}
                          {/*      }}*/}
                          {/*      className={*/}
                          {/*        "hover:text-pink-400/50 cursor-pointer"*/}
                          {/*      }*/}
                          {/*    />*/}

                          {/*    <TrashIcon*/}
                          {/*      onClick={async () => {*/}
                          {/*        await addTrendToBlockList({*/}
                          {/*          trends_ids: [id],*/}
                          {/*        });*/}
                          {/*        void fetchTable();*/}
                          {/*      }}*/}
                          {/*      className={"hover:text-red-600 cursor-pointer"}*/}
                          {/*    />*/}
                          {/*  </div>*/}
                          {/*)}*/}

                          {/*{listType === "black" && (*/}
                          {/*  <div*/}
                          {/*    className={*/}
                          {/*      "flex gap-[4px] items-center justify-center"*/}
                          {/*    }*/}
                          {/*  >*/}
                          {/*    <ResetIcon*/}
                          {/*      onClick={async () => {*/}
                          {/*        await removeTrendsByListName({*/}
                          {/*          trends_ids: [id],*/}
                          {/*          listType: "black",*/}
                          {/*        });*/}
                          {/*        void fetchTable();*/}
                          {/*      }}*/}
                          {/*      className={*/}
                          {/*        "hover:text-pink-400/50 cursor-pointer"*/}
                          {/*      }*/}
                          {/*    />*/}

                          {/*    <HeartIcon*/}
                          {/*      onClick={async () => {*/}
                          {/*        await addTrendToWatchList({*/}
                          {/*          trends_ids: [id],*/}
                          {/*        });*/}
                          {/*        void fetchTable();*/}
                          {/*        await refetchMe();*/}
                          {/*      }}*/}
                          {/*      className={*/}
                          {/*        "hover:text-pink-400/50 cursor-pointer"*/}
                          {/*      }*/}
                          {/*    />*/}
                          {/*  </div>*/}
                          {/*)}*/}
                        </div>
                      </TableCell>
                    }

                    {entries.map((entry) => {
                      const cellValue = entry[1];
                      const columnName = entry[0];
                      const hiddenRow =
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ColumnSettingsByColumnNameInOrder?.[columnName]?.hidden;
                      const isHidden =
                        hiddenRow || hiddenRowsIds.includes(columnName);

                      const cellSettings =
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ColumnSettingsByColumnNameInOrder?.[columnName] || null;

                      const renderCell = () => {
                        if (columnName === "sv_change_12m") {
                          const vals = cellValue.split(" / ");

                          return (
                            <div
                              className={
                                "w-full h-[50px] flex flex-row justify-between max-w-[100px]"
                              }
                            >
                              <div
                                className={
                                  "flex justify-center items-center min-w-[49px] max-w-[49px]  text-xs"
                                }
                              >
                                <p>{vals[0]}</p>
                              </div>
                              <div
                                className={" flex min-h-full w-[1px] bg-black"}
                              />

                              <div
                                className={
                                  "flex  justify-center items-center  min-w-[49px] max-w-[49px] text-xs"
                                }
                              >
                                {vals[1]}
                              </div>
                            </div>
                          );
                        }
                        if (columnName === "sv_change") {
                          const yoyVals =
                            cellValue?.["sv_change_yoy"].split(" / ");
                          const sixMonthVals =
                            cellValue?.["sv_change_6m"].split(" / ");
                          const threeMonthVals =
                            cellValue?.["sv_change_3m"].split(" / ");

                          return (
                            <div className="w-full h-[50px] flex flex-row justify-between max-w-[270px]">
                              <div className="flex flex-row gap-1 justify-center items-center min-w-[89px] max-w-[89px] text-xs">
                                <ValueDisplay value={yoyVals[0]} />
                                <p>/</p>
                                <ValueDisplay value={yoyVals[1]} />
                              </div>
                              <div className="flex flex-row justify-center gap-1 items-center min-w-[89px] max-w-[89px] text-xs">
                                <ValueDisplay value={sixMonthVals[0]} />
                                <p>/</p>
                                <ValueDisplay value={sixMonthVals[1]} />
                              </div>
                              <div className="flex flex-row justify-center gap-1 items-center min-w-[89px] max-w-[89px] text-xs">
                                <ValueDisplay value={threeMonthVals[0]} />
                                <p>/</p>
                                <ValueDisplay value={threeMonthVals[1]} />
                              </div>
                            </div>
                          );
                        }
                        if (cellSettings !== null) {
                          if (cellSettings?.showDollar) {
                            return `${cellValue}$`;
                          }
                          if (cellSettings?.isCategory) {
                            return (
                              <HoverCard>
                                <HoverCardTrigger>
                                  <span
                                    className={
                                      "max-w-[150px] whitespace-break-spaces line-clamp-2 text-center"
                                    }
                                  >
                                    {cellValue}
                                  </span>
                                </HoverCardTrigger>
                                <HoverCardContent className={"w-full"}>
                                  {cellValue}
                                </HoverCardContent>
                              </HoverCard>
                            );
                          }

                          if (cellSettings?.isSearchTerm) {
                            const link = `https://www.amazon.com/s?k=${cellValue}`;
                            return (
                              <HoverCard>
                                <HoverCardTrigger>
                                  <Button
                                    variant="link"
                                    className={
                                      "cursor-pointer text-blue-500 p-0 m-0  "
                                    }
                                  >
                                    <a
                                      href={link}
                                      target="_blank"
                                      className={
                                        "max-w-[150px] whitespace-break-spaces line-clamp-2"
                                      }
                                      rel="noopener noreferrer"
                                    >
                                      {cellValue}
                                    </a>
                                  </Button>
                                </HoverCardTrigger>
                                <HoverCardContent className={"w-full"}>
                                  {cellValue}
                                </HoverCardContent>
                              </HoverCard>
                            );
                          }
                          if (cellSettings?.isChart) {
                            return <ChartExpander data={cellValue} />;
                          }
                          if (
                            cellSettings?.isNumberValueWithKCut ||
                            cellSettings?.isPercent
                          ) {
                            return formatNumber({
                              number: cellValue,
                              isPercentage: cellSettings?.isPercent,
                            });
                          }

                          if (cellSettings?.yesNoValue) {
                            return cellValue ? "Yes" : "No";
                          }

                          if (
                            cellSettings?.isLink &&
                            cellSettings?.hasProductLink
                          ) {
                            if (cellValue === "0") return "-";

                            return (
                              <Button
                                variant="link"
                                className={"text-blue-500 cursor-pointer"}
                              >
                                <a
                                  href={makeLinkFromAsin(cellValue)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                ></a>
                              </Button>
                            );
                          }
                          if (cellSettings?.isLink && !cellSettings?.isImg) {
                            return (
                              <Button
                                variant="link"
                                className={"cursor-pointer text-blue-500"}
                              >
                                <a
                                  href={cellValue}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {cellValue}
                                </a>
                              </Button>
                            );
                          }
                        }

                        if (
                          cellSettings?.isImg &&
                          cellSettings?.hasProductLink
                        ) {
                          const imageLink = cellValue;

                          return (
                            <TopProductPopover row={row}>
                              <Button
                                variant="link"
                                className={"cursor-pointer text-blue-500 h-fit"}
                              >
                                <a
                                  href={productLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {imageLink !== "None" ? (
                                    <img
                                      src={imageLink}
                                      className="max-h-[35px] max-w-[35px] rounded"
                                      width={100}
                                      height={100}
                                      alt={cellValue}
                                    />
                                  ) : (
                                    <img
                                      src={"placeholder-image.png"}
                                      className="max-h-[35px] max-w-[35px] rounded"
                                      width={100}
                                      height={100}
                                      alt={cellValue}
                                    />
                                  )}
                                </a>
                              </Button>
                            </TopProductPopover>
                          );
                        }
                        return cellValue;
                      };

                      return (
                        <TableCell
                          className={`${(isHidden || Object.keys(POPUP_COLUMNS).includes(columnName)) && "hidden"} 
                        border-r-gray-300 border-r !font-xs !font-normal`}
                        >
                          <div className="flex flex-col items-center justify-center ">
                            {renderCell()}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <ScrollBar
            forceMount
            orientation="vertical"
            className={"z-[20] !bg-gray-500/50 fixed "}
          />

          <ScrollBar
            orientation="horizontal"
            className="z-[20] !bg-gray-500/50"
          />
        </div>
      </ScrollArea>
      <div className={"flex flex-col items-center justify-center"}>
        <TablePagination
          totalPagesCount={totalPagesCount}
          currentPaginationPage={currentPaginationPage}
          moveToPaginationPage={moveToPaginationPageHandler}
        />
        <div>
          <span className={"text-lg"}>{totalRows} </span>
          <span className={"text-sm"}>Total items</span>
        </div>
      </div>
    </div>
  );
};
