import { Input, InputProps } from "@/components/ui/input.tsx";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { ClientFilters } from "@/hooks/usePaginatedTrends.tsx";
import { OptionFilterReturn } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ColumnSettingsByColumnNameInOrder } from "@/hooks/ColumnSettingsByColumnName/ColumnSettingsByColumnNameInOrder.tsx";
import { debounce } from "lodash";
import { useMe } from "@/hooks/useMe.tsx";

interface NumericRangeFilterProps {
  minKey?: ClientFilters;
  maxKey?: ClientFilters;
}

interface MinMaxInputProps
  extends InputProps,
    PropsWithChildren,
    NumericRangeFilterProps {
  onSave: ({ filters }: { filters: OptionFilterReturn[] }) => void;
  dropFilter?: boolean;

  queryKey?: ClientFilters;
  selectedSavedSearch?: any[];
}

export function MinMaxInput({
  onSave,
  children,
  dropFilter,
  queryKey,
  selectedSavedSearch,
  minKey = `${queryKey}_min`,
  maxKey = `${queryKey}_max`,
}: MinMaxInputProps) {
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");

  const { me } = useMe({});
  const filterList = me?.filter_list || [];

  const percentShouldShown =
    ColumnSettingsByColumnNameInOrder?.[queryKey]?.isPercent;

  const debouncedHandler = ({ key, val }: { key: any; val: any }) => {
    onSave({
      filters: [
        {
          filterValue: val,
          queryKey: key,
        },
      ],
    });
  };

  const debouncedOnSave = useMemo(() => debounce(debouncedHandler, 300), []);

  useEffect(() => {
    if (dropFilter) {
      setMaxValue("");
      setMinValue("");
      onSave({
        filters: [
          {
            filterValue: null,
            queryKey: minKey,
          },
        ],
      });

      onSave({
        filters: [
          {
            filterValue: null,
            queryKey: maxKey,
          },
        ],
      });
    }
  }, [dropFilter]);

  useEffect(() => {
    if (filterList.length !== 0) {
      const min = filterList?.find((f) => f.queryKey === minKey)?.filterValue;

      const max = filterList?.find((f) => f.queryKey === maxKey)?.filterValue;

      if (min) {
        setMinValue(min || "");
        onSave({
          filters: [
            {
              filterValue: min || null,
              queryKey: minKey,
            },
          ],
        });
      }
      if (max) {
        setMaxValue(max || "");
        onSave({
          filters: [
            {
              filterValue: max || null,
              queryKey: maxKey,
            },
          ],
        });
      }
    }
  }, []);

  useEffect(() => {
    const appliedFilterMin = selectedSavedSearch?.find(
      (f) => f?.queryKey === minKey,
    )?.filterValue;

    const appliedFilterMax = selectedSavedSearch?.find(
      (f) => f?.queryKey === maxKey,
    )?.filterValue;

    if (appliedFilterMin !== undefined) {
      setMinValue(appliedFilterMin || "");
      onSave({
        filters: [
          {
            filterValue: appliedFilterMin || null,
            queryKey: minKey,
          },
        ],
      });
    }

    if (appliedFilterMax !== undefined) {
      setMaxValue(appliedFilterMax || "");
      onSave({
        filters: [
          {
            filterValue: appliedFilterMax || null,
            queryKey: maxKey,
          },
        ],
      });
    }
  }, [maxKey, minKey, onSave, queryKey, selectedSavedSearch]);

  const setMinValueHandler = (value: string) => {
    setMinValue(value);
  };

  const filterText = queryKey
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ColumnSettingsByColumnNameInOrder[queryKey]?.filterName ||
      ColumnSettingsByColumnNameInOrder[queryKey]?.tootipText ||
      ColumnSettingsByColumnNameInOrder[queryKey]?.shownName
    : "-";
  return (
    <div>
      <div>
        {/*<div className="flex flex-row justify-between text-sm text-muted-foreground">*/}
        {/*  <div> Min: {minKey}</div>*/}
        {/*  <div> Max: {maxKey}</div>*/}
        {/*</div>*/}
        <div
          className={`${(maxValue || minValue) && "!text-pallete-black"} text-sm font-light  text-gray-500 mb-[8px] truncate`}
        >
          {children || filterText} {percentShouldShown ? "%" : ""}
        </div>
      </div>
      <div className="flex flex-row gap-2 ">
        <div className="flex flex-row">
          <Input
            placeholder={`Min `}
            onChange={(e) => {
              const value = e.target.value;
              setMinValueHandler(value);
              const minVal = value ? Number(value) : null;
              debouncedOnSave({ key: minKey, val: minVal });
            }}
            value={minValue}
            type={"number"}
            id="height"
            defaultValue="25px"
            className="h-8 border-0 border-b border-gray-400 rounded-none !shadow-none max-w-[100px]"
          />
        </div>
        <div
          className={
            "flex relative top-[5px] font-light  text-sm text-gray-400"
          }
        >
          -
        </div>
        <div className="flex flex-row">
          <Input
            placeholder={`Max`}
            onChange={(e) => {
              const value = e.target.value;
              setMaxValue(value);
              const maxValue = value ? Number(value) : null;
              debouncedOnSave({ key: maxKey, val: maxValue });
            }}
            value={maxValue}
            type={"number"}
            id="maxHeight"
            defaultValue="none"
            className="h-8 border-0 border-b border-gray-400 rounded-none !shadow-none max-w-[100px]"
          />
        </div>
      </div>
    </div>
  );
}
