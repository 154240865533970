import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs.tsx";

import { LoginForm } from "@/pages/LoginPage/LoginForm/LoginForm.tsx";
import { RegistrationPage } from "@/pages/LoginPage/RegistrationPage.tsx";
import useAuthStore from "@/store/useAuthStore.tsx";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const LoginPage = () => {
  const { accessToken } = useAuthStore();
  const navigation = useNavigate();

  useEffect(() => {
    if (accessToken) {
      navigation("/trends");
      return;
    }
  }, []);

  return (
    <div
      className={"flex min-w-screen min-h-screen justify-center items-center"}
    >
      <LoginForm />
    </div>
  );
};
