import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Label } from "@/components/ui/label.tsx";
import { FormInput } from "@/components/form/FormInput.tsx";
import { EMAIL_PATTERN, PASSWORD_PATTERNS } from "@/consts/consts.ts";
import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useRegistrationForm } from "@/pages/LoginPage/useRegistraton.tsx";
import { initializePaddle, Paddle } from "@paddle/paddle-js";
import { useEffect, useState } from "react";

export const RegistrationPage = () => {
  const { form, onSubmit, passwordCheck, setPasswordCheck } =
    useRegistrationForm({ cb: () => setShowPaymentForm(true) });

  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  const email = form.watch("email");

  useEffect(() => {
    if (showPaymentForm) {
      initializePaddle({
        environment: "sandbox",
        token: "test_07c7fc7675a003b63037d4be3ca",
        checkout: {
          settings: {
            displayMode: "inline",
            successUrl: "https://app.nextbigniche.ai/trends",
            showAddDiscounts: false,
          },
        },
      }).then((paddleInstance: Paddle | undefined) => {
        paddleInstance?.Checkout.open({
          customer: {
            email,
          },
          settings: {
            theme: "light",
            frameTarget: "test-payment",
            frameStyle: "position:relative;min-width:486px;",
          },
          customData: {
            email,
          },
          items: [{ priceId: "pri_01j55zhez3gdrz8m41y2g72evq", quantity: 1 }],
        });
      });
    }
  }, [email, showPaymentForm]);

  return (
    <div className="flex min-w-screen min-h-screen justify-center items-center ">
      <Card className={"min-w-[500px] p-[16px]"}>
        <div className={"flex w-full flex-row "}></div>
        <div className={"flex w-full gap-4"}>
          <div className={"flex flex-col w-full gap-[1px]"}>
            <div
              className={
                "flex w-full border-b-2 border-pallete-orange rounded-full"
              }
            />
            <div className="text-gray-400 text-[12px]">Step 1</div>
            <div className={"text-sm"}>Create Account</div>
          </div>
          <div className={"flex flex-col w-full gap-[1px]"}>
            <div
              className={`flex w-full border-b-2  ${!showPaymentForm ? "border-pallete-orange/40" : "border-purple-400"}  rounded-full`}
            />
            <div
              className={`text-gray-400 text-[12px] ${!showPaymentForm && "text-gray-300"}`}
            >
              Step 2
            </div>
            <div className={`text-sm  ${!showPaymentForm && "text-gray-300"}`}>
              Payment Details
            </div>
          </div>
        </div>
        {showPaymentForm ? (
          <div className={"min-h-[600px]"}>
            <div className={"test-payment"} />
          </div>
        ) : (
          <Card className="!border-none !shadow-none">
            <CardHeader></CardHeader>
            <CardContent>
              <form>
                <div className="grid w-full items-center gap-[26px]">
                  <div className="flex flex-col space-y-1.5">
                    <Label className={"text-muted-foreground"}>Email</Label>
                    <FormInput
                      form={form}
                      fieldName={"email"}
                      validation={{
                        pattern: {
                          message: "Please, type valid email",
                          value: EMAIL_PATTERN,
                        },
                        required: "Email is required",
                      }}
                    />
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label className={"text-muted-foreground"}>
                      Email confirmation
                    </Label>
                    <FormInput
                      form={form}
                      fieldName={"emailConfirmation"}
                      validation={{
                        required: "Emails do not match",
                        validate: (value) => {
                          return (
                            value === form.watch("email") ||
                            "Emails do not match"
                          );
                        },
                      }}
                    />
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label className={"text-muted-foreground"}>Password</Label>
                    <FormInput
                      form={form}
                      validation={{
                        pattern: {
                          message: "password validation",
                          value: PASSWORD_PATTERNS,
                        },
                        required: "Password is required",
                        minLength: { value: 6, message: "Short password" },
                      }}
                      fieldName="password"
                      type={"password"}
                    />
                  </div>
                </div>
              </form>
            </CardContent>
            <CardFooter className="flex flex-col gap-[10px] justify-between">
              <Button onClick={onSubmit} className="w-full">
                Next
              </Button>
            </CardFooter>
          </Card>
        )}
      </Card>
    </div>
  );
};
