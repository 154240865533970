import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import useAuthStore from "@/store/useAuthStore.tsx";
import axios from "axios";
import { API_URl } from "@/consts/consts.ts";
import { toast } from "sonner";
import { useNavigation } from "react-router-dom";

export const useRegistrationForm = ({ cb }: { cb: () => void }) => {
  const { setToken } = useAuthStore();
  const navigation = useNavigation();
  const [passwordCheck, setPasswordCheck] = useState("");

  const form = useForm<{
    email: string;
    password: string;
    emailConfirmation: string;
  }>({
    mode: "onSubmit",
    shouldFocusError: false,
    defaultValues: {
      password: "",
      email: "",
      emailConfirmation: "",
    },
  });

  const onLogin = useCallback(async () => {
    const { email, password } = form.getValues();
    const url = `/signup`;
    try {
      const { data } = await axios.post<{
        access_token: string;
        refresh_token: string;
      }>(
        url,
        {
          email,
          password,
        },
        { baseURL: API_URl },
      );
      setToken({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
      });
      cb();

      navigation("/trends");
    } catch (error) {
      console.error("signup error", error.response.data.message);
      toast.error(error.response.data.message);
    }

    form.reset({ username, password: "" });
    setPasswordCheck("");
  }, [form, navigation, setToken]);

  return useMemo(
    () => ({
      form,
      onSubmit: form.handleSubmit(onLogin),
      passwordCheck,
      setPasswordCheck,
    }),
    [form, onLogin, passwordCheck],
  );
};
