import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useUserSettings } from "@/hooks/useUserSettings.tsx";
import { useState } from "react";

export function SaveSearchDialog({
  appliedFilters,
}: {
  appliedFilters: any[];
}) {
  const { addNewSavedSearch } = useUserSettings();

  const [searchName, setSearchName] = useState("");
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"outline"} size={"sm"} className="w-fit">
          Save search
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Save your search</DialogTitle>
          <DialogDescription>Please add name</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <Input
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose>
            <Button
              type="submit"
              size={"sm"}
              onClick={async () => {
                await addNewSavedSearch({
                  saveSearch: appliedFilters,
                  name: searchName,
                });
                setSearchName("");
              }}
            >
              Save changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
