import axios from "axios";
import { API_URl } from "@/consts/consts.ts";
import { toast } from "sonner";
import useAuthStore from "@/store/useAuthStore.tsx";
import { ListType } from "@/hooks/usePaginatedTrends.tsx";
import { useQueryClient } from "@tanstack/react-query";

export const useUserSettings = () => {
  const { accessToken } = useAuthStore();
  const queryClient = useQueryClient();

  const addTrendToBlockList = async ({
    trends_ids,
  }: {
    trends_ids: string[];
  }) => {
    const url = `/set-black-list`;

    try {
      await axios.post<{
        trends_ids: string[];
      }>(
        url,
        {
          trends_ids,
        },
        {
          baseURL: API_URl,
          headers: {
            Authorization: accessToken,
          },
        },
      );
      toast.message("Blocklist updated");
    } catch (error) {
      console.error("signup error", error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const removeTrendsByListName = async ({
    trends_ids,
    listType,
  }: {
    trends_ids: string[];
    listType: ListType;
  }) => {
    const url = `/remove-from-list-by-list-name`;

    try {
      await axios.post<{
        trends_ids: string[];
        list_type: ListType;
      }>(
        url,
        {
          trends_ids,
          list_type: listType,
        },
        {
          baseURL: API_URl,
          headers: {
            Authorization: accessToken,
          },
        },
      );
      toast.message(`${listType}list updated`);
    } catch (error) {
      console.error("signup error", error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const addTrendToWatchList = async ({
    trends_ids,
  }: {
    trends_ids: string[];
  }) => {
    const url = `/set-watch-list`;

    try {
      await axios.post<{
        trends_ids: string[];
      }>(
        url,
        {
          trends_ids,
        },
        {
          baseURL: API_URl,
          headers: {
            Authorization: accessToken,
          },
        },
      );
      toast.message("Watchlist updated");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const addFiltersList = async ({ filterList }: { filterList: any[] }) => {
    const url = `/set-filter-list`;

    try {
      await axios.post<{
        trends_ids: string[];
      }>(
        url,
        {
          filter_list: filterList,
        },
        {
          baseURL: API_URl,
          headers: {
            Authorization: accessToken,
          },
        },
      );
    } catch (error) {
      toast.warning(error?.response?.data?.message || "Set filters error");
    } finally {
      await queryClient.refetchQueries({ queryKey: ["me"] });
    }
  };

  const addNewSavedSearch = async ({
    saveSearch,
    name = "",
  }: {
    saveSearch: any[];
    name?: string;
  }) => {
    const url = `/add-new-saved-search`;

    try {
      await axios.post<{
        saved_search: any[];
        name?: string;
      }>(
        url,
        {
          saved_search: saveSearch,
          name,
        },
        {
          baseURL: API_URl,
          headers: {
            Authorization: accessToken,
          },
        },
      );
      toast.message("Saved search added");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      await queryClient.refetchQueries({ queryKey: ["me"] });
    }
  };

  const deleteSavedSearchById = async ({ id }: { id: string }) => {
    const url = `/delete-saved-search-by-id`;

    try {
      await axios.post(
        url,
        {
          id,
        },
        {
          baseURL: API_URl,
          headers: {
            Authorization: accessToken,
          },
        },
      );
      toast.message(`Saved search deleted`);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error deleting saved search",
      );
    } finally {
      await queryClient.refetchQueries({ queryKey: ["me"] });
    }
  };

  return {
    addTrendToBlockList,
    addTrendToWatchList,
    addFiltersList,
    removeTrendsByListName,
    addNewSavedSearch,
    deleteSavedSearchById,
  };
};
