import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Button } from "@/components/ui/button.tsx";
import { FilteredColumns } from "@/hooks/usePaginatedTrends.tsx";
import { ColumnSettingsByColumnNameInOrder } from "@/hooks/ColumnSettingsByColumnName/ColumnSettingsByColumnNameInOrder.tsx";
import { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";

import { MixerHorizontalIcon } from "@radix-ui/react-icons";

export const ColumnsSettings = ({
  hiddenRowsIds,
  hideColumn,
}: {
  hiddenRowsIds: FilteredColumns[];
  hideColumn: (col: FilteredColumns) => void;
}) => {
  const columnsInOrder = Object.keys(
    ColumnSettingsByColumnNameInOrder,
  ) as FilteredColumns[];

  const filteredCols = columnsInOrder.filter(
    (col) =>
      !ColumnSettingsByColumnNameInOrder[col]?.hidden &&
      !ColumnSettingsByColumnNameInOrder[col]?.isPopup,
  );
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu open={isOpen}>
      <DropdownMenuTrigger onClick={() => setIsOpen(true)}>
        <Button variant="outline" size={"sm"}>
          <MixerHorizontalIcon className={"mr-[8px]"} />
          Columns visibility
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-56"
        onInteractOutside={() => {
          setIsOpen(false);
        }}
      >
        <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <ScrollArea className={"h-[250px]"}>
          {filteredCols.map((col, i) => (
            <DropdownMenuCheckboxItem
              key={i}
              checked={!hiddenRowsIds.includes(col)}
              onCheckedChange={() => {
                hideColumn(col);
              }}
            >
              {ColumnSettingsByColumnNameInOrder?.[col]?.shownName || col}
            </DropdownMenuCheckboxItem>
          ))}
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
