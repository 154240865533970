import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TrendsPaginatedResponse } from "@/types/types.ts";
import { API_URl } from "@/consts/consts.ts";
import { toast } from "sonner";
import useAuthStore from "@/store/useAuthStore.tsx";

export const useCategoriesList = () => {
  const { accessToken } = useAuthStore();

  const {
    isPlaceholderData,
    data: categories = [],
    refetch,
  } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [`categories-list`],
    queryFn: async ({ signal }) => {
      try {
        const url = `/category`;
        const resp = await axios.get<TrendsPaginatedResponse>(url, {
          signal,
          baseURL: API_URl,
          headers: {
            Authorization: accessToken,
          },
        });
        return resp?.data?.category;
      } catch (err: any) {
        if (err?.code === "ERR_CANCELED") return null;
        toast.error(err?.message);
        return null;
      }
    },
    staleTime: 0,
  });

  return {
    isLoading: isPlaceholderData,
    categories: categories || [],
    refetchCategories: refetch,
  };
};
