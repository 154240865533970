import { Label } from "@/components/ui/label.tsx";
import { FormInput } from "@/components/form/FormInput.tsx";
import { PASSWORD_PATTERNS } from "@/consts/consts.ts";
import { Button } from "@/components/ui/button.tsx";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { useLoginForm } from "@/pages/LoginPage/useLoginForm.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";

export const LoginForm = () => {
  const { form, onSubmit } = useLoginForm();

  return (
    <Card className="!border-none !shadow-none w-full sm:w-96 flex flex-col gap-4">
      <CardHeader>
        <div className={"flex flex-col items-center justify-center gap-4"}>
          <p className="text-text-2 font-light text-2xl">Log In</p>
          <p className={"font-semibold text-text-1 text-3xl"}>Next Big Niche</p>
        </div>
      </CardHeader>
      <CardContent className="w-full">
        <form>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <Label className={"text-muted-foreground"}>Email</Label>
              <FormInput
                form={form}
                fieldName={"email"}
                validation={{
                  required: "Email is required",
                }}
              />
            </div>
            <div className="flex flex-col space-y-1.5">
              <Label className={"text-muted-foreground"}>Password</Label>
              <FormInput
                form={form}
                validation={{
                  value: PASSWORD_PATTERNS,
                  required: "Password is required",
                }}
                fieldName="password"
                type={"password"}
              />
            </div>
          </div>
        </form>
      </CardContent>

      <CardFooter className="flex justify-between">
        <div className="flex flex-col w-full items-center gap-[14px]">
          <div className={"flex w-full  flex-row"}>
            <div className="flex items-center justify-center">
              <Checkbox id="rembmer" className={"mr-1"} />
              <Label htmlFor="rembmer" className={"text-muted-foreground "}>
                Remember me
              </Label>
            </div>
          </div>

          <Button onClick={onSubmit} className={" w-full"}>
            Login
          </Button>

          <div>
            <span className={"text-muted-foreground"}>
              New to <span>Next big niche</span>?
            </span>

            <a
              target="_blank"
              href={"https://nextbigniche.ai/#plans-and-pricing"}
              className={
                "text-link cursor-pointer hover:brightness-150 transition ml-[4px]"
              }
            >
              Sign Up Now
            </a>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};
