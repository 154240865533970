import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { useCategoriesList } from "@/hooks/useCategoriesList.tsx";
import { FC, useCallback, useEffect, useState } from "react";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { MinusCircledIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { DialogClose } from "@radix-ui/react-dialog";
import { OptionFilterReturn } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ClientFilters } from "@/hooks/usePaginatedTrends.tsx";
import { isObject } from "lodash";
import { isEmptyObject } from "@/lib/utils.ts";
import { useMe } from "@/hooks/useMe.tsx";

type Category = {
  category: string;
  child: Category[];
  id: number;
};

type TreeNodeProps = {
  node: Category;
  searchTerm: string;
  selectedCategories: Record<number, number[]>;
  setSelectedCategories: (newSelection: Record<number, number[]>) => void;
  level: number;
};

const updateSelection = (
  node: Category,
  isChecked: boolean,
  selectedCategories: Record<number, number[]>,
  setSelectedCategories: (newSelection: Record<number, number[]>) => void,
) => {
  const updatedSelection = { ...selectedCategories };

  if (isChecked) {
    updatedSelection[node.id] = node.child.map((child) => child.id);
  } else {
    delete updatedSelection[node.id];
  }

  setSelectedCategories(updatedSelection);
  // localStorage.setItem("selectedCategories", JSON.stringify(updatedSelection));
};

const toggleChildCheck = (
  node: Category,
  childId: number,
  isChecked: boolean,
  selectedCategories: Record<number, number[]>,
  setSelectedCategories: (newSelection: Record<number, number[]>) => void,
) => {
  const updatedSelection = { ...selectedCategories };

  if (!updatedSelection[node.id]) {
    updatedSelection[node.id] = [];
  }

  if (isChecked) {
    updatedSelection[node.id].push(childId);
  } else {
    updatedSelection[node.id] = updatedSelection[node.id].filter(
      (id) => id !== childId,
    );

    if (updatedSelection[node.id].length === 0) {
      delete updatedSelection[node.id];
    }
  }

  setSelectedCategories(updatedSelection);
  localStorage.setItem("selectedCategories", JSON.stringify(updatedSelection));
};

const TreeNode: React.FC<TreeNodeProps> = ({
  node,
  searchTerm,
  selectedCategories,
  setSelectedCategories,
  level,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const isChecked = selectedCategories[node.id]?.length === node.child.length;
  const isIndeterminate = selectedCategories[node.id]?.length > 0 && !isChecked;

  const toggleCheck = () => {
    updateSelection(
      node,
      !isChecked,
      selectedCategories,
      setSelectedCategories,
    );
  };

  const handleChildCheck = (childId: number, isChecked: boolean) => {
    toggleChildCheck(
      node,
      childId,
      isChecked,
      selectedCategories,
      setSelectedCategories,
    );
  };

  const hasMatchingChild = (node: Category): boolean => {
    return node.child.some(
      (child) =>
        child.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        hasMatchingChild(child),
    );
  };

  if (
    !node.category.toLowerCase().includes(searchTerm.toLowerCase()) &&
    !hasMatchingChild(node)
  ) {
    return null;
  }

  const selectedCount = selectedCategories[node.id]?.length || 0;
  const totalCount = node.child.length;

  return (
    <div className="ml-5">
      <div className="flex items-center space-x-2">
        {node.child.length > 0 && level === 0 && (
          <span
            onClick={toggleExpand}
            className="cursor-pointer text-gray-700 hover:text-gray-900"
          >
            {isExpanded ? <MinusCircledIcon /> : <PlusCircledIcon />}
          </span>
        )}
        <Checkbox
          className="mr-2"
          checked={isChecked}
          onCheckedChange={toggleCheck}
          indeterminate={isIndeterminate}
        />
        <span className="flex-1">{node.category}</span>
        {node.child.length > 0 && (
          <span className="text-gray-500 pr-10">
            {selectedCount} of {totalCount}
          </span>
        )}
      </div>
      {isExpanded && level === 0 && (
        <div className="ml-5">
          {node.child.map((childNode, index) => (
            <div
              key={childNode.id + index}
              className="flex items-center space-x-2 ml-5"
            >
              <Checkbox
                checked={selectedCategories[node.id]?.includes(childNode.id)}
                onCheckedChange={(checked) =>
                  handleChildCheck(childNode.id, checked as boolean)
                }
              />
              <span>{childNode.category}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

type TreeProps = {
  data: Category[];
  searchTerm: string;
  selectedCategories: Record<number, number[]>;
  setSelectedCategories: (newSelection: Record<number, number[]>) => void;
};

const Tree: React.FC<TreeProps> = ({
  data,
  searchTerm,
  selectedCategories,
  setSelectedCategories,
}) => {
  return (
    <div>
      {data.map((node) => (
        <TreeNode
          key={node.id}
          node={node}
          searchTerm={searchTerm}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          level={0}
        />
      ))}
    </div>
  );
};

interface CategoryFilterProps {
  onSave: ({ filters }: { filters: OptionFilterReturn[] }) => void;
  queryKey: ClientFilters;
  popoverContent?: string;
  dropFilter?: boolean;
  selectedSavedSearch: any[];
}

export const CategoryFilter: FC<CategoryFilterProps> = ({
  onSave,
  queryKey,
  dropFilter,
  selectedSavedSearch,
}) => {
  const { categories } = useCategoriesList();
  const { me } = useMe({});
  const filterList = me?.filter_list || [];

  const [searchTerm, setSearchTerm] = useState("");

  const [selectedCategories, setSelectedCategories] = useState<
    Record<number, number[]>
  >(() => {
    const savedState = localStorage.getItem(queryKey);
    return savedState ? JSON.parse(savedState) : {};
  });

  function transformStructure(input) {
    const result = {};

    for (const [parentId, childIds] of Object.entries(input)) {
      result[parentId] = {};

      for (const childId of childIds) {
        result[parentId][childId] = {};
      }
    }

    return result;
  }

  function reverseTransformStructure(input) {
    const result = {};

    for (const [parentId, children] of Object.entries(input)) {
      result[parentId] = Object.keys(children);
    }

    return result;
  }
  const onSaveHandler = useCallback(() => {
    if (isObject(selectedCategories) && isEmptyObject(selectedCategories)) {
      onSave({
        filters: [
          {
            filterValue: null,
            queryKey,
          },
        ],
      });
      return;
    }

    onSave({
      filters: [
        {
          filterValue: transformStructure(selectedCategories),
          queryKey,
        },
      ],
    });
  }, [onSave, queryKey, selectedCategories]);

  useEffect(() => {
    if (filterList.length !== 0) {
      const foundFilter = filterList?.find((f) => f.queryKey === queryKey);
      if (foundFilter !== undefined) {
        setSelectedCategories(
          reverseTransformStructure(foundFilter.filterValue),
        );

        if (isObject(foundFilter) && isEmptyObject(foundFilter)) {
          onSave({
            filters: [
              {
                filterValue: null,
                queryKey,
              },
            ],
          });
          return;
        }
        onSave({
          filters: [
            {
              filterValue: foundFilter.filterValue,
              queryKey,
            },
          ],
        });
      }
    }
  }, []);

  useEffect(() => {
    const appliedFilter = selectedSavedSearch?.find(
      (f) => f?.queryKey === queryKey,
    );

    if (appliedFilter !== undefined) {
      setSelectedCategories(
        reverseTransformStructure(appliedFilter.filterValue),
      );

      if (isObject(appliedFilter) && isEmptyObject(appliedFilter)) {
        onSave({
          filters: [
            {
              filterValue: null,
              queryKey,
            },
          ],
        });
        return;
      }
      onSave({
        filters: [
          {
            filterValue: appliedFilter.filterValue,
            queryKey,
          },
        ],
      });
    }
  }, [onSave, queryKey, selectedSavedSearch]);

  useEffect(() => {
    if (dropFilter) {
      setSelectedCategories({});
      onSave({ filters: [{ queryKey, filterValue: "" }] });
    }
  }, [dropFilter]);

  const selectedParentCount = Object.keys(selectedCategories).length;
  const selectedSubcategoryCount =
    Object.values(selectedCategories).flat().length;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Select Categories</Button>
      </DialogTrigger>
      <DialogContent className={"h-[calc(100vh-100px)]"}>
        <DialogHeader>
          <DialogTitle>Select Categories</DialogTitle>
        </DialogHeader>
        {/*<Input*/}
        {/*  type="text"*/}
        {/*  placeholder="Category search"*/}
        {/*  value={searchTerm}*/}
        {/*  onChange={(e) => setSearchTerm(e.target.value)}*/}
        {/*  className="mb-4 p-2 w-full border border-gray-300 rounded"*/}
        {/*/>*/}
        <ScrollArea className="h-[calc(100vh-250px)]">
          <Tree
            data={categories}
            searchTerm={searchTerm}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </ScrollArea>

        <DialogFooter>
          <div className={"flex justify-between w-full items-center"}>
            {/*<div className="text-gray-900">*/}
            {/*  {selectedParentCount} categories and {selectedSubcategoryCount}{" "}*/}
            {/*  subcategories selected*/}
            {/*</div>*/}
            <Button
              variant={"secondary"}
              onClick={() => {
                setSelectedCategories({});
              }}
            >
              Clear all
            </Button>

            <DialogClose onClick={onSaveHandler}>
              <Button>Save and exit</Button>
            </DialogClose>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
