import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URl } from "@/consts/consts.ts";
import { toast } from "sonner";
import useAuthStore from "@/store/useAuthStore.tsx";

export const useMe = ({ setFilters }: { setFilters?: any }) => {
  const { accessToken } = useAuthStore();

  const { data: me, refetch } = useQuery({
    queryKey: ["me"],
    queryFn: async ({ signal }) => {
      try {
        const url = `/me`;
        const resp = await axios.get<{
          watch_list: string[];
          black_list: string[];
          filter_list: any[];
          saved_searches: any[];
        }>(url, {
          signal,
          baseURL: API_URl,
          headers: {
            Authorization: accessToken,
            withCredentials: true,
            "Access-Control-Allow-Credential": true,
          },
        });
        setFilters && setFilters(resp?.data?.filter_list || []);
        return resp.data;
      } catch (err: any) {
        if (err?.code === "ERR_CANCELED") return null;
        toast.error(err?.message);
        return null;
      }
    },
  });

  return {
    me,
    refetchMe: refetch,
  };
};
