import { FC, PropsWithChildren } from "react";

interface FilterContainerProps extends PropsWithChildren {
  filterSize?: number;
  className?: string;
}
export const FilterContainer: FC<FilterContainerProps> = ({
  children,
  filterSize = 300,
}) => {
  return (
    <div
      className={`w-[${filterSize}px] flex flex-row items-center  justify-between`}
    >
      {children}
    </div>
  );
};
