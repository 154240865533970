import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useAuthStore from "@/store/useAuthStore.tsx";

export const UserLayout = () => {
  const { accessToken } = useAuthStore();
  const isAuthenticated = !!accessToken;

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={"flex h-screen w-screen"}>
      <div className={"absolute  top-[10px] right-[10px]"}></div>
      <Outlet />
    </div>
  );
};
