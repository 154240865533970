import { QueryClient } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { TrendsPage } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ThemeProvider } from "@/providers/theme-provider.tsx";
import { Toaster } from "@/components/ui/sonner";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { CategoryScale, Chart } from "chart.js";
import { LoginPage } from "@/pages/LoginPage/LoginPage.tsx";
import { UserLayout } from "@/pages/UserLayout/UserLayout.tsx";
import { RegistrationPage } from "@/pages/LoginPage/RegistrationPage.tsx";
import { TestLayout } from "@/pages/TestLayout.tsx";
import { ErrorBoundaryPage } from "@/pages/ErorrBoundaryPage.tsx";
import Smartlook from "smartlook-client";

import * as Sentry from "@sentry/react";
Chart.register(CategoryScale);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      cacheTime: 1000 * 60 * 60 * 12,
      enabled: true,
    },
    mutations: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      cacheTime: 1000 * 60 * 60 * 12,
      enabled: true,
    },
  },
});
const persister = createSyncStoragePersister({
  storage: window.sessionStorage,
});

const router = createBrowserRouter([
  {
    errorElement: import.meta.env.PROD ? <ErrorBoundaryPage /> : null,
    children: [
      {
        path: "/",
        element: <TestLayout />,
        children: [
          {
            path: "registration",
            element: <RegistrationPage />,
          },
          {
            path: "/",
            element: <LoginPage />,
          },
        ],
      },
      {
        path: "*",
        element: <LoginPage />,
      },
      {
        element: <UserLayout />,
        path: "/trends",
        children: [{ path: "/trends", element: <TrendsPage /> }],
      },
    ],
  },
]);

function App() {
  if (import.meta.env.PROD) {
    Smartlook.init("d14294a7952f784958794ad48af5f379cf75bbb6", {
      region: "eu",
    });
    Sentry.init({
      dsn: "https://5d722bca39e42457e2f9a489ea3edd4e@o4507893592752128.ingest.us.sentry.io/4507893602713600",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/app\.nextbigniche\.ai\//],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <RouterProvider router={router} />
        <Toaster />
      </ThemeProvider>
    </PersistQueryClientProvider>
  );
}

export default App;
